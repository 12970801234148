import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import type { GetServerSideProps } from 'next';
import Link from 'next/link';
import styled from 'styled-components';

import BookCarousel from '@components/BookCarousel';
import BookThemes from '@components/BookThemes';
import BookTypes from '@components/BookTypes';
import Collaborate from '@components/Collaborate';
import EditorExample from '@components/EditorExample';
import Example from '@components/Example';
import Footer from '@components/Footer';
import Gift from '@components/Gift';
import GoodToKnow from '@components/GoodToKnow';
import Navigation from '@components/Navigation';
import Promise from '@components/Promise';
import Reviews from '@components/Reviews';
import ReviewsRating from '@components/ReviewsRating';
import USP from '@components/USP';
import UspBar from '@components/UspBar';
import {
  backgroundColored,
  brand,
  fontDark,
  fontMedium,
} from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { bookChoice, productionUrl } from '@constants/routes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import CustomHead from '@elements/CustomHead';
import Text from '@elements/Text';
import Title from '@elements/Title';
import { PageGetFeaturedComp, ssrGetFeatured } from '@graphql/generated/page';

const StyledMain = styled.main`
  background-color: ${backgroundColored};
`;

const Wrapper = styled.div`
  padding: 0 0 ${space * 10}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  color: ${fontDark};
  width: 100%;
  justify-content: center;
  text-align: center;
  padding: ${space * 8}px ${space * 3}px ${space * 8}px;

  ${tablet} {
    padding-top: ${space * 12}px;
  }
`;

const StyledTitle = styled(Title)`
  color: ${brand};

  @media screen and (min-width: 1272px) {
    font-size: 64px;
    line-height: 72px;
  }
`;

const StyledText = styled(Text)`
  color: ${fontMedium};
  font-size: 20px;
  line-height: 30px;
  padding-bottom: ${space * 4}px;

  ${tablet} {
    font-size: 24px;
    line-height: 36px;
  }
`;

const Home: PageGetFeaturedComp = () => {
  const { data } = ssrGetFeatured.usePage();

  return (
    <>
      <CustomHead
        title="Maak samen een fotoboek. Voor jezelf of als cadeau"
        description="Met Belmondofoto maak je alleen of samen, eenvoudig je eigen fotoboek als cadeau voor je vriend(in), je familie of je collega. Zo leg  je in een handomdraai blijvende herinneringen vast."
        canonicalUrl={productionUrl}
      />
      <Navigation backgroundColor={backgroundColored} />
      <StyledMain>
        <Wrapper>
          <Content>
            <StyledTitle size="xxl" as="h1" className="mt-8">
              Het fotoboek dat je samen maakt
            </StyledTitle>
            <StyledText className="mt-24" weight={400}>
              Jullie meest waardevolle cadeau voor nu en later
            </StyledText>
            <Link href={bookChoice} passHref>
              <a>
                <Button iconRight={faArrowRightLong} size="big">
                  Begin met ontwerpen
                </Button>
              </a>
            </Link>
          </Content>
          <BookCarousel />
          <UspBar />
          <ReviewsRating />
        </Wrapper>
        <BookThemes />
        <Promise />
        <USP />
        <EditorExample />
        <Collaborate />
        <Gift />
        <Example />
        <BookTypes />
        <Reviews />
        <GoodToKnow />
      </StyledMain>
      <Footer />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  return await ssrGetFeatured.getServerPage({}, ctx);
};

export default Home;
